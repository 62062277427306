import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-powered-by-genius-white-svg',
    imports: [CommonModule],
    templateUrl: './powered-by-genius-white-svg.svg',
    styleUrl: './powered-by-genius-white-svg.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoweredByGeniusWhiteSvgComponent {

}
